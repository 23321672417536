document.addEventListener('DOMContentLoaded', function() {
  var AOS = require('aos');
  AOS.init({
    startEvent: 'turbolinks:load', // If you are using turbolinks
    once: 'true'
  });
});

$(document).on('turbolinks:load', function() {

// Mobile hamburger menu functionality
  (function() {
      var burger = document.querySelector('.burger');
      var menu = document.querySelector('#'+burger.dataset.target);
      burger.addEventListener('click', function() {
          burger.classList.toggle('is-active');
          menu.classList.toggle('is-active');
      });
  })();

// General page scroll
  $('.scroll_down').click( function() {
    var identity = $(this).attr('id');
    $('html, body').animate({
        scrollTop: $('#' + 'our_' + identity).offset().top - 64
    }, 1500);
  });

// Navbar mobile dropdown
  $(".navbar-item.has-dropdown").click(function () {
    $(this).children(".navbar-dropdown").toggleClass("is-active");
  });

// Navbar menu stop background scroll bug fix
$("#disable_background_scroll").on( "click", function() {
  $("body").toggleClass( "fixed-position" );
});

// Annual accounts pricing boxes
  var selected_option = document.getElementById('select-turnover');
  if (selected_option) {
      document.getElementById('price_sole').innerHTML = '£' + 0
      document.getElementById('price_part').innerHTML = '£' + 0
      document.getElementById('price_ltd').innerHTML = '£' + 0
    selected_option.onchange = function() {
      document.getElementById('price_sole').innerHTML = '£' + this.options[this.selectedIndex].getAttribute('data-soletrader');
      document.getElementById('price_part').innerHTML = '£' + this.options[this.selectedIndex].getAttribute('data-partnership');
      document.getElementById('price_ltd').innerHTML = '£' + this.options[this.selectedIndex].getAttribute('data-ltd');
    }
  }

// Modal
  function toggleModalClasses(event) {
    var modalId = event.currentTarget.dataset.modalId;
    var modal = $(modalId);
    modal.toggleClass('is-active');
    $('html').toggleClass('is-clipped');
  };
  $('.open-modal').click(toggleModalClasses);
  $('.close-modal').click(toggleModalClasses);

// Accordion
  var acc = document.getElementsByClassName("accordion");
  var i;

if (acc) {
  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
}

// cc-floating cookieconsent banner bug fix.
$(document).ready(function() {
  $('.cc-floating').removeClass('cc-floating').addClass('cc-banner');
});

//
});
